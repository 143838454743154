// Colors
$white: #fff;
$primary-green: #55B336;
$dark-green: #00b72b;
$darker-green: #0d9f00;
$dark-gray: #707070;
$gray-200: #E4E4E5;
$gray-600: #767676;
$light-gray: #D4D4D4;
$text-dark: #292929;
$gray: #495964;
$gray-darker: #2d373e;
$red: #FF0202;
$blue: #2185d0;
$blue-darker: #0272ce;
$sidebar-bg: #3a3a3a;
$sidebar-bg-secondary: #343434;
$primary-pink: #E72F19;
$light-pink: #F4BCC2;

// Font families
$font-family: 'Lato', sans-serif, serif;
$secondary-font: 'HelveticaRounded-Bold', sans-serif;

// Font sizes
$font-size-xxl-6: 110px;
$font-size-xxl-5: 64px;
$font-size-xxl-4: 46px;
$font-size-xxl-3: 42px;
$font-size-heading: 30px;
$font-size-xxl-1: 22px;
$font-size-xxl: 20px;
$font-size-xl: 18px;
$font-size-large: 16px;
$font-size-medium: 14px;
$font-size-medium-xs: 12px;
$font-size-small: 11px;
$font-size-xs: 10px;

// Font Weights
$font-weight-bold: 700;
$font-weight-normal: 400;
$font-weight-thin: 300;

// Border radius
$border-radius-small: 5px;
$border-radius-medium: 10px;
$border-radius-big: 18px;
$radius-lg: 18px;

//Spacing
$side-menu-padding-right: 40px;

// Media Queries
@mixin for-mobile {
  @media screen and (max-width: 470px) {
    @content
  }
}

@mixin for-tablet-portrait-and-less {
  @media screen and (max-width: 780px) {
    @content
  }
}

@mixin for-tablet-and-less {
  @media screen and (max-width: 1000px) {
    @content
  }
}

@mixin for-s-laptop-and-less {
  @media screen and (max-width: 1280px) {
    @content
  }
}

@mixin for-small-laptop-and-less {
  @media screen and (max-width: 1440px) {
    @content
  }
}

@mixin for-small-laptop {
  @media screen and (max-width: 1500px) and (min-width: 1280px) {
    @content
  }
}

@mixin modalAbsoluteFooter($property) {
  position: absolute;

  bottom: -75px;
  #{$property}: 60px;

  @include for-s-laptop-and-less {
    bottom: -55px;
    #{$property}: 10px;
  }
}

// Provides a way to access these values in JS
:export {
  white: $white;
  primaryGreen: $primary-green;
  darkGray: $dark-gray;
  gray200: $gray-200;
  gray600: $gray-600;
  lightGray: $light-gray;
  textDark: $text-dark;
  gray: $gray;
  grayDarker: $gray-darker;
  red: $red;
  blue: $blue;
  sidebarBg: $sidebar-bg;
  sidebarBgSecondary: $sidebar-bg-secondary;
  primaryPink: $primary-pink;
  lightPink: $light-pink;
}


/*
  From here on down are the old values.
  The application's view was built around them.
  Do not remove them unless an overhaul of the entire app is being made.
 */

/* ==========================================================================
   Reset values.
   ========================================================================== */


   body, html {
    height: initial !important;
    //min-width: initial; should be added once all brikks use the new component
    min-height: 100vh;
    line-height: initial !important;
  }
  
  input,
  select,
  textarea {
    font-family: $font-family !important;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p {
    margin: 0 !important;
  }
  
  body {
    font-family: 'Roboto', sans-serif, serif !important;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  #root {
    height: 100%;
  }
  
  
  /* ==========================================================================
     SemanticUI overrides.
     ========================================================================== */
  
  
  body.blurring.dimmable > .dimmer, .ui.dimmer {
    background-color: rgba(0, 0, 0, 0.3);
    perspective: unset !important;
  }
  
  
  /* Checkbox color */
  .ui.toggle.checkbox input:focus:checked ~ .box:before,
  .ui.toggle.checkbox input:focus:checked ~ label:before,
  .ui.toggle.checkbox input:checked ~ .box:before,
  .ui.toggle.checkbox input:checked ~ label:before {
    background-color: var(--color--primary) !important;
  }
  
  .ui.toggle.checkbox .box:before, .ui.toggle.checkbox label:before {
    background-color: #A8B7C7 !important;
    border: 1px solid var(--color--primary) !important;
  }
  
  .ui.toggle.checkbox input:focus:checked ~ label,
  .ui.toggle.checkbox input:checked ~ label {
    color: inherit !important;
  }
  
  a {
    .basic.button.ui[role='button'],
    .basic.button.ui[role='button']:hover {
      border: none;
      box-shadow: none;
      font-weight: bold;
    }
  
    &:hover {
      cursor: pointer;
    }
  }

@import "~global/styles";

.checkbox {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, auto);
  grid-column-gap: 25px;
  padding: 0.6rem 0;
  justify-content: end;
  align-items: center;
  border-bottom: 1px solid #292828;

  .disabled {
    color: $gray-600 !important;
  }

  .text {
    color: $white
  }

  .disabledLayer {
    position: absolute;
    right: 0;
    cursor: not-allowed !important;
    height: 42px;
    width: 140px;
    opacity: 0;
    z-index: 10;
  }
}





